import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import DownloadSampleCSV from "./downloadCsv";
import upload from "../../../../asset/cloudUpload.svg";
import file from "../../../../asset/file.svg";
import del from "../../../../asset/trash.svg";
import { useAppDispatch } from "../../../../redux/hook/authHook";
import { bulkEmployeesUpload } from "../../../../redux/slices/employes/bulkUpload";
import TitleSection from "../../../../utilities/headers/titleSection";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function BulkIndex() {
  const [uploading, setUploading] = useState(false);
  const [showUploadContent, setShowUploadContent] = useState(true);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [uploadedFileSize, setUploadedFileSize] = useState("");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  function formatFileSize(bytes: number): string {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  }

  const handleUploadButtonClick = () => {
    setUploading(true);
    setShowUploadContent(false);

    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".csv";

    fileInput.addEventListener("change", (event) => {
      if (event.target instanceof HTMLInputElement) {
        const file = event.target.files?.[0];

        if (file) {
          // File selected, proceed with upload
          setUploadedFileName(file.name);
          setUploadedFileSize(formatFileSize(file.size));
          sendToBackend(file);
        } else {
          console.log(" herenmk");
          resetFileInput();
          setUploading(false);
          window.location.reload();
        }
      }
    });

    fileInput.click();
  };

  const sendToBackend = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    console.log(file);

    setUploading(true);

    try {
      const response = await dispatch(bulkEmployeesUpload(formData));

      if (response.payload?.responseData?.statusCode === 400) {
        toast.error("Failed Upload", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#F04438", color: "white" },
        });
        resetFileInput();
      } else {
        toast.success("Account created successfully", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#00989B", color: "white" },
        });
      }

      console.log("Backend response:", response);
    } catch (error) {
      console.error("Error sending file to backend:", error);
      resetFileInput();
    } finally {
      setUploading(false);
    }
  };

  const resetFileInput = () => {
    setUploadedFileName("");
    setUploadedFileSize("");
    setShowUploadContent(true);
  };

  const handleCancelClick = () => {
    navigate(-1);
  };

  return (
    <div>
      <TitleSection
        title="Add Bulk Employees"
        subtitle="Upload relevant information and save"
        greenBtnLink=""
        grayButtonText="Cancel"
        grayBtnLink=""
        onCancelButtonClick={handleCancelClick}
        greenButtonIcon=""
        grayButtonIcon=""
      />
      <hr className="text-[#EAECF0] pb-10" />
      <div className="p-4 shadow-md rounded-xl border border-transparent">
        <TitleSection
          title="Upload Employee’s Record"
          subtitle="Upload and attach files to this project."
          greenBtnLink=""
          grayButtonText=""
          grayBtnLink=""
          greenButtonIcon=""
          grayButtonIcon=""
        />
        <div className="border rounded-lg border-greyWhite px-6 py-8">
          {showUploadContent ? (
            <div className="flex flex-col items-center justify-center w-full h-[50px] py-8">
              <div className="mb-4">
                <ReactSVG src={upload} />
              </div>
              <p className="text-lg font-normal text-center">
                <div
                  onClick={handleUploadButtonClick}
                  className="inline-flex pr-1"
                >
                  <span className="text-greenDark">Click to upload</span>
                </div>
                or drag and drop
              </p>
              <p className="text-sm font-light text-center">
                *The uploaded file format must be CSV
              </p>
            </div>
          ) : (
            <div className="py-4">
              {uploading ? (
                <div>Uploading...</div>
              ) : (
                <div>
                  <div className="flex justify-between">
                    <ReactSVG src={file} />
                    <div className="flex-grow mx-2">
                      <p>{uploadedFileName}</p>
                      <p>{uploadedFileSize}</p>
                    </div>
                    <div onClick={resetFileInput}>
                      <ReactSVG src={del} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div>
          <DownloadSampleCSV />
        </div>
        <div>
          <button
            onClick={handleUploadButtonClick}
            disabled={uploading}
            className={`w-full ${
              uploading ? "bg-greenWhite" : "bg-greenDark"
            } font-medium text-lg py-3 border rounded-lg text-white text-center`}
          >
            Add Bulk Employees
          </button>
        </div>
      </div>
    </div>
  );
}

export default BulkIndex;
